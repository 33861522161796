/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import siteImage from "../images/logo.jpg"

function SEO({
  description,
  lang,
  meta,
  title,
  image,
  url,
  keywords,
  defaultTitle,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            url
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaDefaultTitle = defaultTitle
    ? ` ${defaultTitle}`
    : ` | ${site.siteMetadata?.title}`
  const metaUrl = url || site.siteMetadata.url
  const metaImage = image || siteImage

  const metaKeywords = keywords || ["cloud", "cloud solutions"]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={metaDefaultTitle ? `%s  ${metaDefaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
        metaKeywords && metaKeywords.length > 0
          ? {
              name: `keywords`,
              content: metaKeywords,
            }
          : []
      )}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: `Metsi Technologies is a leading cloud computing company offering cloud-based products, consultancy & management services to all businesses.`,
  image: siteImage,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
